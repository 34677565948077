<template>
  <PopupComi style="z-index: 5" v-if="showPopupDrowComisionamiento">
    <div class="header-close">
      <img
        style="cursor: pointer"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h2 style="color: white; text-align: center">
        {{ $t("message.planta.f9") }}
      </h2>
    </div>
    <form class="popup-form" action="" @submit="confirmSubmitSoporte">
      <div class="file-upload-container">
        <input type="file" id="file-upload" class="file-upload-input" ref="fileInput" />

        <label for="file-upload" class="file-upload-label">{{
          $t("message.planta.f10")
        }}</label>
        <div class="btn-content">
          <button
            @click.prevent="cargarArchivo()"
            class="btn-submit"
            type="submit"
            id="btn-cerrar-popup2"
            style="margin-top: 1rem; margin-left: 1rem"
          >
            <strong>{{ $t("message.planta.f11") }}</strong>
          </button>
        </div>

        <div>
          <DataTable class="table-upload-file" style="width: 400px">
            <Column field="code" header="Code"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="category" header="Category"></Column>
            <Column field="quantity" header="Quantity"></Column>
          </DataTable>
        </div>
      </div>
    </form>
  </PopupComi>

  <PopupDrop style="z-index: 5" v-if="showPopupDrow && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f12") }}
        {{ String(terminalSelected.terminals_nickname).toUpperCase() }}
      </h2>
    </div>
    <form class="popup-form" action="">
      <div class="group">
        <v-select
          v-model="newService.planservice"
          :options="changeserviceplan"
          label="name"
          :placeholder="$t('message.planta.f13')"
          class="custom-select"
        />
      </div>

      <div class="btn-content">
        <button
          @click.prevent="cambioPlan(terminalSelected)"
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-top: 1rem; margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f14") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <PopupDrop style="z-index: 5" v-if="showPopupDrowSus && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f28") }}</h1>
    </div>
    <form class="popup-form">
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f29") }}
          {{ terminalSelected.terminals_nickname.toUpperCase() }} ?
        </h3>
      </div>

      <div class="btn-content">
        <button
          @click.prevent="suspender(terminalSelected)"
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f30") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <PopupDrop style="z-index: 5" v-if="showPopupDrowBaja && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h1 style="color: white">{{ $t("message.planta.f19") }}</h1>
    </div>
    <form class="popup-form" action="" @submit="confirmSubmitBaja">
      <div class="group">
        <h3 style="color: white; line-height: 25px">
          {{ $t("message.planta.f20") }}
          {{ terminalSelected.terminals_nickname.toUpperCase() }} ?
        </h3>
      </div>

      <div class="btn-content">
        <button
          @click.prevent="bajaTotal(terminalSelected)"
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f21") }}</strong>
        </button>
      </div>
    </form>
  </PopupDrop>

  <Popup style="z-index: 5" v-if="showPopupDrowSoporte && terminalSelected">
    <div class="header-close">
      <img
        style="cursor: pointer; margin-top: -1rem"
        width="25"
        height="25"
        src="@/assets/eliminar-50.png"
        alt="delete-sign--v1"
        @click="closePopup"
      />
    </div>
    <div class="header-content">
      <h2 style="color: white">
        {{ $t("message.planta.f22") }} {{ terminalSelected.nickname }}
      </h2>
    </div>

    <form
      class="popup-form"
      @submit.prevent="generarTicket(ticketSoporte, terminalSelected)"
    >
      <div class="group">
        <v-select
          v-model="ticketSoporte.typeContact"
          :options="problemtypes.dataTypeContactId"
          label="name"
          placeholder="Selecciona el Tipo de Contacto"
          class="custom-select"
        />
      </div>

      <div class="group">
        <v-select
          v-model="ticketSoporte.mediaComunication"
          :options="problemtypes.dataMediaComunicationId"
          label="name"
          placeholder="Selecciona la Media Comunicacion"
          class="custom-select"
        />
      </div>

      <div class="group">
        <v-select
          v-model="ticketSoporte.request"
          :options="problemtypes.dataPinProblem"
          label="tree_name"
          placeholder="Selecciona el tipo de solicitud"
          class="custom-select"
        />
      </div>

      <div class="group">
        <textarea
          v-model="ticketSoporte.descripcion"
          class="custom-input"
          type=""
          :placeholder="$t('message.planta.f26')"
          style="padding-left: 0.5rem; width: 318px; height: 87px"
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
      </div>

      <div class="btn-content">
        <button
          class="btn-submit"
          type="submit"
          id="btn-cerrar-popup2"
          style="margin-top: 1rem; margin-left: 1rem"
        >
          <strong>{{ $t("message.planta.f27") }}</strong>
        </button>
      </div>
    </form>
  </Popup>

  <!-- <BajaSubscription style="z-index: 5" v-if="showPopupBajaSuscription" :terminalSelected="terminalSelected" @close="closePopup()">
  </BajaSubscription> -->

  <SuspenderSubscription
    style="z-index: 5"
    v-if="showPopupSuspenderSuscription"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </SuspenderSubscription>

  <ReactivarSubscription
    style="z-index: 5"
    v-if="showPopupReactivarSuscription"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </ReactivarSubscription>

  <newPlant style="z-index: 5" v-if="showPopup" @close="closePopup()"></newPlant>
  <ChangeAdrees
    style="z-index: 5"
    v-if="showPopupAddress"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  >
  </ChangeAdrees>
  <BajaValidation
    style="z-index: 5"
    v-if="showPopupBajaValidation"
    :terminalSelected="terminalSelected"
    @close="closePopup()"
  ></BajaValidation>

  <div style="z-index: 1" class="table-content">
    <div class="buscar-content">
      <!-- <div
        style="margin-left: 0px !important; cursor: pointer"
        class="nuevo-servicio"
        @click="showPopup = true"
      >
        <div>{{ $t("message.home.btn2") }}</div>
        <div>
          <img src="@/assets/Vector-servicio.png" alt="img-vector" />
        </div>
      </div> -->
      <div></div>
      <div style="margin-right: 0px !important" class="buscar-servicio">
        <input
          v-model="query"
          class="buscador"
          type="text"
          :placeholder="$t('message.planta.search')"
          name=""
          id=""
          style="font-size: 16px"
        />
        <button class="circle">
          <img src="@/assets/Vector-search.png" alt="img-search" />
        </button>
        <div class="btn-content">
          <button @click="exportCSV($event)">{{ $t("message.export") }}</button>
        </div>
      </div>
    </div>
    <DataTable
      :value="filteredTerminals"
      paginator
      :rows="8"
      ref="dt"
      :rowsPerPageOptions="[8]"
      stripedRows
      tableStyle="min-width: 1300px"
    >
      <!-- <template #header>
        <div style="text-align: left">
           
        </div>
    </template> -->
      <Column
        class="title-c"
        v-for="(column, index) in myColumnTablet"
        :key="'column-' + index"
        :sortable="column.sort"
        :field="column.value"
        :header="$t(column.ts)"
        :style="'color:#000000!important;text-align:center;'"
      >
        <template v-if="column.childrem && column.name === 'Action'" #body="{ data }">
          <!-- <button
            class="menu-button"
            @click.prevent="data.show === true ? (data.show = false) : (data.show = true)"
          >
            <img src="@/assets/menu.png" alt="burguer-menu" />
          </button> -->
          <!-- <form @mouseleave="data.show = false">
            <Menu id="overlay_menu" ref="menu" style="position: absolute" :popup="!data.show">
              <template #start>

                
                <button @click="
                  terminalSelect(data);
                  showPopupBajaSuscription = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.f31")
                      }}</span>
                    </div>
                  </div>
                </button>

                <button v-if="data.subscriptions_state==='ACTIVE'" @click="
                  terminalSelect(data);
                  showPopupSuspenderSuscription = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.f32")
                      }}</span>
                    </div>
                  </div>
                </button>

                <button v-if="data.subscriptions_state==='SUSPENDED'" @click="
                  terminalSelect(data);
                  showPopupReactivarSuscription = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.f33")
                      }}</span>
                    </div>
                  </div>
                </button>

                

                <button v-if="data.subscriptions_state==='ACTIVE' || data.subscriptions_state==='SUSPENDED'" @click.prevent="gotografana(data)"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold"> 360 </span>
                    </div>
                  </div>
                </button>


                
                <button v-if="permissions.some((evt: any) => evt.id === 12)" @click="
                  showPopupDrowComisionamiento = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op1")
                      }}</span>
                    </div>
                  </div>
                </button>
                <button v-if="permissions.some((evt: any) => evt.id === 13)" @click="
                  terminalSelect(data);
                showPopupDrow = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op2")
                      }}</span>
                    </div>
                  </div>
                </button>
                <button v-if="permissions.some((evt: any) => evt.id === 39)" @click="
                  terminalSelect(data);
                showPopupAddress = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/checkbox-circle.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op3")
                      }}</span>
                    </div>
                  </div>
                </button>
                <button v-if="permissions.some((evt: any) => evt.id === 14)" @click="
                  terminalSelect(data);
                showPopupDrowSus = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/note-edit.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op4")
                      }}</span>
                    </div>
                  </div>
                </button>
                <button v-if="permissions.some((evt: any) => evt.id === 15)" @click="
                  terminalSelect(data);
                showPopupDrowBaja = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/trash.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op5")
                      }}</span>
                    </div>
                  </div>
                </button>
                <button v-if="permissions.some((evt: any) => evt.id === 16)" @click="
                  terminalSelect(data);
                showPopupDrowSoporte = true;
                data.show = false;
                " class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/handshake.svg" alt="" />
                      <span class="font-bold">{{
                        $t("message.planta.op6")
                      }}</span>
                    </div>
                  </div>
                </button>
                <button v-if="permissions.some((evt: any) => evt.id === 42)" @click.prevent="gotografana(data)"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold">
                      360
                      </span>
                    </div>
                  </div>
                </button>

                <button v-if="permissions.some((evt: any) => evt.id === 42)" @click.prevent="goto360(data)"
                  class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
                  <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" class="mr-2"
                    shape="circle" />
                  <div class="flex flex-column align">
                    <div class="flexing-menu">
                      <img src="@/assets/signal.svg" alt="" />
                      <span class="font-bold">360</span>
                    </div>
                  </div>
                </button>
              </template>
            </Menu>
          </form> -->

          <!-- Si NO es ACTIVE, no se dibuja nada -->
          <div v-if="data.subscriptions_state === 'ACTIVE'">
            <button class="menu-button" @click.prevent="data.show = !data.show">
              <img src="@/assets/menu.png" alt="burguer-menu" />
            </button>

            <form @mouseleave="data.show = false">
              <Menu
                id="overlay_menu"
                ref="menu"
                style="position: absolute"
                :popup="!data.show"
              >
                <template #start>
                  <!-- Botón 360, visible solo en ACTIVE -->
                  <button
                    @click.prevent="gotografana(data)"
                    class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround"
                  >
                    <Avatar
                      image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                      class="mr-2"
                      shape="circle"
                    />
                    <div class="flex flex-column align">
                      <div class="flexing-menu">
                        <img src="@/assets/signal.svg" alt="" />
                        <span class="font-bold">360</span>
                      </div>
                    </div>
                  </button>
                </template>
              </Menu>
            </form>
          </div>
        </template>
        <template
          v-else-if="column.childrem && column.name === 'Estado'"
          #body="{ data }"
        >
          <button
            :class="data.subscriptions_state === 'ACTIVE' ? 'btn-active' : 'btn-inactive'"
          >
            {{
              data.subscriptions_state === "ACTIVE" ? "ACTIVE" : data.subscriptions_state
            }}
          </button>
        </template>
        <template
          v-else-if="column.childrem && column.name === 'Ultima Conexión'"
          #body="{ data }"
        >
          {{ String(data.billingConsumption_lastDailyDate).substring(0, 10) }}
        </template>
        <template
          v-else-if="column.childrem && column.name === 'Dirección'"
          #body="{ data }"
        >
          {{ String(data.addressRoute).substring(0, 40) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Popup from "../../components/planta-conte/Popup.vue";
import PopupDrop from "../../components/planta-conte/PopupDropDown.vue";
import PopupComi from "../../components/planta-conte/PopupComisionamiento.vue";

import ChangeAdrees from "./poppup/updateAddress.vue";
import BajaValidation from "./poppup/bajaValidation.vue";
import store from "../../store/index";
import { ApiResponsePlantaArray } from "../../interface/planta";
import { mapGetters, mapState } from "vuex";
import newPlant from "./newService.vue";
import vSelect from "vue-select";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css"; /* Deprecated */
import Menu from "primevue/menu";
// import BajaSubscription from "./poppup/bajaSubscription.vue";
import SuspenderSubscription from "./poppup/suspenderSubscription.vue";
import ReactivarSubscription from "./poppup/reactivarSubscription.vue";
export default defineComponent({
  name: "planta",
  components: {
    Popup,
    PopupDrop,
    newPlant,
    vSelect,
    DataTable,
    Column,
    Menu,
    ChangeAdrees,
    PopupComi,
    Button,
    BajaValidation,
    // BajaSubscription,
    SuspenderSubscription,
    ReactivarSubscription,
  },
  computed: {
    ...mapState({
      coordenate: (state: any) => state.AUTH.coordenateData,
      user: (state: any) => state.AUTH.user,
      token: (state: any) => state.AUTH.token,
    }),
    ...mapGetters(["filteredTerminals", "permissions", "mycolumns"]),
    query: {
      get() {
        return store.state.dataFilter.text;
      },
      set(value: any) {
        store.commit("SET_FILTER_TERMINALS", {
          filter: "text",
          value,
        });
      },
    },
  },
  data() {
    return {
      showPopup: false,
      showPopupAddress: false,
      showPopupDrow: false,
      showPopupDrowSus: false,
      showPopupDrowBaja: false,
      showPopupDrowSoporte: false,
      showPopupBajaValidation: false,
      showPopupDrowComisionamiento: false,
      // showPopupBajaSuscription:false,
      showPopupSuspenderSuscription: false,
      showPopupReactivarSuscription: false,
      newService: {
        siteid: "",
        planservice: "",
        direction: "",
        serialnumber: "",
        suscriptor: "",
      },
      columnTablet: [
        {
          name: "Empresa",
          ts: "message.planta.c1",
          value: "principal_name",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 122,
        },
        {
          name: "Nombre Suscriptor",
          value: "subscribers_name",
          ts: "message.planta.c3",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 123,
        },
        {
          name: "Site ID",
          ts: "message.planta.c2",
          value: "subscriptions_sai",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 124,
        },

        {
          name: "Estado",
          ts: "message.planta.c5",
          value: "subscriptions_state",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 126,
        },
        {
          name: "Estado Online",
          ts: "message.planta.c55",
          value: "subscriptions_online_status",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 126,
        },
        {
          name: "Plan de Servicio",
          ts: "message.planta.c10",
          value: "serviceplans_name",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "7.5",
          rule: 127,
        },
        {
          name: "lat",
          ts: "message.planta.c13",
          value: "locations_latitude",
          mode: "ASC",
          sort: true,
          childrem: false,
          width: "5",
          rule: 128,
        },
        {
          name: "Long",
          ts: "message.planta.c12",
          value: "locations_longitude",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "5",
          rule: 129,
        },
        {
          name: "Fecha_activacion",
          ts: "message.planta.c11",
          value: "subscriptions_activationdate",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "15",
          rule: 130,
        },
        {
          name: "Serie del modem",
          ts: "message.planta.c4",
          value: "subscriptions_mac_address",
          mode: "ASC",
          sort: true,
          childrem: true,
          width: "6",
          rule: 125,
        },
        {
          name: "Action",
          ts: "message.planta.c14",
          value: null,
          mode: "ASC",
          sort: false,
          childrem: true,
          width: "5",
          rule: 131,
        },
      ],
      myColumnTablet: [],
      changeplan: "",
      changeserviceplan: [],
      busqueda: null,
      dataSource: [] as ApiResponsePlantaArray,
      originalDataSource: [] as ApiResponsePlantaArray,
      resultadosBusqueda: [] as ApiResponsePlantaArray,
      terminalSelected: null,
      ticketSoporte: {
        request: "",
        descripcion: "",
        mediaComunication: "",
        typeContact: "",
      },
      problemtypes: [],
      // problemtypes1: [],
      show: true,
    };
  },
  mounted() {
    // this.problemtype();
    setTimeout(() => {
      var self = this;
      var mycolumns: (
        | {
            name: string;
            value: string;
            mode: string;
            sort: boolean;
            childrem: boolean;
            width: string;
            rule: number;
          }
        | {
            name: string;
            value: null;
            mode: string;
            sort: boolean;
            childrem: boolean;
            width: string;
            rule: number;
          }
      )[] = [];
      this.columnTablet.forEach((columnT) => {
        if (this.permissions.some((evt: any) => evt.id === columnT.rule) === true) {
          mycolumns.push(columnT);
        }
      });
      console.log("okok");
      this.mycolu(mycolumns);
      if (this.permissions.some((evt: any) => evt.id === 119)) {
        var data = {
          endpoint: 84,
          type: 1,
        };
        this.serviceline(data);
      }
      // this.serviceplan();
    }, 2000);
  },

  methods: {
    sendTicket() {
      console.log("ticketSoporte", this.ticketSoporte);
    },
    exportCSV() {
      var data: any = this.$refs.dt;
      if (data) {
        data.exportCSV();
      }

      // dt.value.exportCSV();
    },

    gotografana(terminalSelected: any) {
      var data: { [key: string]: any } = {
        sai: terminalSelected.subscriptions_sai,
        terminalId: terminalSelected.terminal_id,
        subscriptionId: terminalSelected.subscription_id,
      };
      data["var-principal"] = terminalSelected.subscriptions_sai;
      store
        .dispatch("GETMONITORINGVIASAT", data)
        .then((response) => {
          let url = response.data.snapshotUrl;
          const hasQueryParams = url.includes("?");
          const separator = hasQueryParams ? "&" : "?";

          // Agregamos los parámetros terminalId y subscriptionId en la URL
          const urlWithParams = `${url}${separator}kiosk&terminalId=${data.terminalId}&subscriptionId=${data.subscriptionId}`;

          // const urlWithKioskParam = url + separator + "kiosk";
          window.open(urlWithParams, "_blank");
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    goto360(terminal: any) {
      var idTerminal = null;
      if (terminal.bifrost2Id) {
        idTerminal = terminal.bifrost2Id;
      } else {
        idTerminal = terminal.id;
      }
      var url = " https://laravel360.bifrost.pe/demo/" + idTerminal;
      window.open(url, "_blank");
    },
    verificarAddress() {
      store
        .dispatch("GET_COORDENADE", this.newService.direction)
        .then((response) => {
          store.dispatch("showToast", {
            message: "VERIFICADO",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    mycolu(data: any) {
      if (data.length > 0) {
        this.myColumnTablet = data;
      } else {
        return [];
      }
    },
    serviceline(data: any) {
      console.log(data);
      store
        // .dispatch("SERVICELINE", data)
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          // console.log(this.newService);
          store.dispatch("showToast", {
            message: "LISTADO DE PLANTA",
            type: "success",
          });
          // this.$router.push("/home");

          this.originalDataSource = response.data;
          // console.log(response.data)
          this.showPopup = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    soporte() {
      var data = {
        username: "develop@bifrost.pe",
        password: "developbifrost",
        search: "TEST2112",
        problem: "1",
        description: "Descripción Ticket Prueba Starlink",
        fullname: "Usuario Prueba",
        email: "leonardo25457@gmail.com",
      };
      store
        .dispatch("SOPORTE", data)
        .then((response) => {
          console.log(this.newService);
          store.dispatch("showToast", {
            message: "SE ENVIO EL TICKET DE SOPORTE",
            type: "success",
          });
          this.showPopupDrowSoporte = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    serviceplan() {
      var data = {
        endpointId: 17,
        token: this.token,
        principalId: this.user.principalId,
        platform: "ses",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
      };
      store
        .dispatch("SERVICELINEUSERCONFIG", data)
        .then((response) => {
          // store.dispatch("showToast", {
          //     message: "LISTADO DE PLANTA",
          //     type: "success",
          // });
          // this.$router.push("/home");
          // this.originalDataSource = response.data;
          console.log(response.data.results);
          this.changeserviceplan = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // problemtype() {
    //   store
    //     .dispatch("PROBLEMTYPE")
    //     .then((response) => {
    //       // store.dispatch("showToast", {
    //       //     message: "LISTADO DE PLANTA",
    //       //     type: "success",
    //       // });
    //       // this.$router.push("/home");
    //       // this.originalDataSource = response.data;
    //       console.log(response);
    //       this.problemtypes = response.data;
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //     });
    // },

    problemtype() {
      store
        .dispatch("PROBLEMTYPE")
        .then((response) => {
          // store.dispatch("showToast", {
          //     message: "LISTADO DE PLANTA",
          //     type: "success",
          // });
          // this.$router.push("/home");
          // this.originalDataSource = response.data;
          console.log("problemtype", response);
          this.problemtypes = response.data.data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    cambioPlan(e: any) {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          console.log(this.changeplan);
          store.dispatch("showToast", {
            message: "SE CAMBIO EL PLAN EXISTOSAMENTE DE UN SERVICIO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrow = false;
          console.log("datos", e);
          var data = {
            body: `prueba solicitud de cambio de plan ${e.nickname} / ${e.kitSerialNumber}`,
            asunto: "Cambio de plan",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    suspender(e: any) {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE SUSPENDIO EXISTOSAMENTE UN SERVICIO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowSus = false;
          var data = {
            body: `prueba solicitud de suspencion ${e.terminals_nickname} / ${e.kitSerialNumber}`,
            asunto: "baja total",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    bajaTotal(e: any) {
      var datavalid = {
        endpointId: 13,
        principalId: this.user.principalId,
        platform: "ses",
        roleId: this.user.roleId,
        userId: this.user.id,
        orgId: this.user.orgId,
        params: {
          accountNumber: e.accountNumber,
          userTerminalId: e.userTerminalId,
          serviceLineNumber: e.serviceLineNumber,
          terminalId: e.terminals_id,
        },
      };
      store
        .dispatch("CONFIG_FUNCTION", datavalid)
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE HIZO LA BAJA EXISTOSAMENTE DE UN SERVICIO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowBaja = false;
          this.showPopupBajaValidation = true;
          var data = {
            body: ` Estimados Para conocimiento se realizó la baja del servicio ${e.terminals_nickname} asociado al ${e.kitSerialNumber} tener en cuenta lo siguiente, ${e.terminals_nickname} Ya no figura en el listado de planta,
            ${e.kitSerialNumber}esta liberado y disponible para asignar a otro servicio y se seguira facturando hasta el próximo día 5`,
            asunto: `BAJA TOTAL DE SERVICIO ${e.terminals_nickname}`,
            correo_destino: "nocmnla.fija.pe@telefonica.com",
          };
          var data2 = {
            body: ` Estimados Para conocimiento se realizó la baja del servicio ${e.terminals_nickname} asociado al ${e.kitSerialNumber} tener en cuenta lo siguiente, ${e.terminals_nickname} Ya no figura en el listado de planta,
            ${e.kitSerialNumber}esta liberado y disponible para asignar a otro servicio y se seguira facturando hasta el próximo día 5`,
            asunto: `BAJA TOTAL DE SERVICIO ${e.terminals_nickname}`,
            correo_destino: "elvis.shahuano@wydsoluciones.com",
          };
          var data3 = {
            body: ` Estimados Para conocimiento se realizó la baja del servicio ${e.terminals_nickname} asociado al ${e.kitSerialNumber} tener en cuenta lo siguiente, ${e.terminals_nickname} Ya no figura en el listado de planta,
            ${e.kitSerialNumber}esta liberado y disponible para asignar a otro servicio y se seguira facturando hasta el próximo día 5`,
            asunto: `BAJA TOTAL DE SERVICIO ${e.terminals_nickname}`,
            correo_destino: "ronald.tamayol@telefonica.com",
          };
          var data4 = {
            body: ` Estimados Para conocimiento se realizó la baja del servicio ${e.terminals_nickname} asociado al ${e.kitSerialNumber} tener en cuenta lo siguiente, ${e.terminals_nickname} Ya no figura en el listado de planta,
            ${e.kitSerialNumber}esta liberado y disponible para asignar a otro servicio y se seguira facturando hasta el próximo día 5`,
            asunto: `BAJA TOTAL DE SERVICIO ${e.terminals_nickname}`,
            correo_destino: this.user.email,
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
          store
            .dispatch("SEND_MAIL", data2)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
          store
            .dispatch("SEND_MAIL", data3)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
          var datarefresh = {
            endpointId: 16,
            token: this.token,
            principalId: this.user.principalId,
            platform: "ses",
            roleId: this.user.roleId,
            userId: this.user.id,
            orgId: this.user.orgId,
            elementId: 23,
          };
          this.serviceline(datarefresh);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    generarTicket(data: any, terminal: any) {
      var dataEmail = {
        body: data.descripcion,
        asunto: `${data.request.tree_name} - ${data.email} - ${terminal.terminals_nickname}`,
        correo_destino: "nocmnla.fija.pe@telefonica.com",
      };
      store
        .dispatch("SEND_MAIL", dataEmail)
        .then((response) => {
          this.showPopupDrowSoporte = false;
          store.dispatch("showToast", {
            message: "SE ENVIO CON EXITO SU CONSULTA A SOPORTE",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });

      let created = {
        tableReference: "starlink",
        referenceId: terminal.id,
        pinProblemId: data.request.id,
        description: data.descripcion,
        contactIncidence: this.user.fullname,
        mediaComunicationId: data.mediaComunication.id,
        dataComunication: data.mediaComunication.name,
        typeContactId: data.typeContact.id,
        platform: 127,
      };

      // console.log('created', created)
      store
        .dispatch("CREATEDSUPPORT", created)
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE ENVIO CON EXITO SU CONSULTA A SOPORTE",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowSoporte = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    cargarArchivo() {
      store
        .dispatch("HELLOWORD")
        .then((response) => {
          store.dispatch("showToast", {
            message: "SE CARGO CORRECTAMENTE EL ARCHIVO",
            type: "success",
          });
          // this.$router.push("/home");
          this.showPopupDrowComisionamiento = false;
          var data = {
            body: "se cargo un archivo",
            asunto: "Nuevo archivo",
            correo_destino: "juliocesar.herranlarosa@telefonica.com",
          };
          store
            .dispatch("SEND_MAIL", data)
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    closePopup() {
      // if (!event.target.closest(".popup-form")) {
      this.showPopup = false;
      this.showPopupDrow = false;
      this.showPopupDrowSus = false;
      this.showPopupDrowBaja = false;
      this.showPopupDrowSoporte = false;
      this.showPopupAddress = false;
      this.showPopupDrowComisionamiento = false;
      this.showPopupBajaValidation = false;
      // this.showPopupBajaSuscription=false;
      this.showPopupSuspenderSuscription = false;
      this.showPopupReactivarSuscription = false;
      this.terminalSelected = null;
      // }
    },
    confirmSubmitSuspender(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de Suspender?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitBaja(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de Baja Total?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitSoporte(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de enviar tu Consulta?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    confirmSubmitComisionamiento(event: any) {
      event.preventDefault();
      const confirmMessage = "¿Estás seguro de enviar tu Consulta?";
      if (window.confirm(confirmMessage)) {
        // Aquí puedes realizar las acciones de envío del formulario
        console.log("Formulario enviado");
      }
    },
    terminalSelect(value: any) {
      this.terminalSelected = value;
    },
  },
});
</script>
<style lang="scss">
@import "vue-select/dist/vue-select.css";

.table-content {
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  flex-direction: column;
  padding-top: 127px;
  margin-bottom: 100px;
  max-width: 1630px;

  .buscar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    max-width: 1630px;
  }
}

.menu-button {
  background-color: #15059088;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  img {
    width: 20px;
    height: 20px;
  }
}

.title-c {
  span {
    color: #000000 !important;
  }
}

.header-content {
  display: flex;
  justify-content: center;
  // flex-direction: row-reverse;
}

.header-close {
  display: flex;
  justify-content: flex-end;
}

.btn-inactive {
  border: none;
  background-color: #aaaaaa;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.btn-active {
  border: none;
  background-color: #22bf03;
  border-radius: 9px;
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
}

.popup-form .group input {
  width: 100%;
  max-width: 320px;
}

.flexing-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    flex-basis: 10%;
    display: flex;
    justify-content: center;
  }

  span {
    flex-basis: calc(90% - 5px);
    margin-left: 5px;
  }
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.file-upload-label,
.upload-button {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.file-upload-label:hover,
.upload-button:hover {
  opacity: 0.8;
}

.file-upload-input {
  display: none;
}

.btn-content {
  margin-left: 10px;

  button {
    height: 40px;
    cursor: pointer;
    width: 100%;
    border-radius: 9px;
    background-color: #007bff;
    color: #ffffff;
  }
}
.p-column-title {
  color: #000000;
}

.p-dropdown-item-label {
  color: #000000;
}
</style>
